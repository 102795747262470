.skill-section {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-top: 3rem;
	flex-direction: column;
	gap: 2rem;
}
.skill-list {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	max-width: 60rem;
	transition: all 0.3s ease;
}
.skill-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	transition: all 0.3s ease;
}
.skill-icon {
	display: flex;
	height: 5rem;
	width: 5rem;
	border-radius: 1rem;
	background-color: rgb(248, 248, 248);
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.skill-img {
	object-fit: contain;
	overflow-clip-margin: content-box;
	overflow: clip;
	height: 4rem;
	width: 4rem;
	transition: transform 0.3s ease;
}

.skill-img:hover {
	transform: scale(1.1);
}

.skill-name {
	font-weight: 400;
	font-family: Montserrat sans-serif;
}

@media only screen and (max-width: 820px) {
	.skill-section {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-top: 3rem;
		flex-direction: column;
		gap: 2rem;
		width: fit-content;
	}
	.skill-list {
		width: 100%;
	}
}
