.card {
	display: flex;
	flex-direction: column;
	margin: 1.5rem;
	border-radius: 1rem;
	border: 1rem solid transparent;
	transition: all 0.2s ease;
	justify-content: center;
	align-items: center;
	background-color: #f7f7f7;
	border: 2px solid rgb(9 9 9 / 19%);
	width: 14rem;
}
.card-bg-dark {
	background: #fff;
	color: black;
	border: 2px solid #96bbdae6;
}
.image-box {
	display: flex;
	height: 11rem;
	width: 10rem;
	align-items: center;
	justify-content: center;
}
.card-image {
	width: 100%;
	object-fit: cover;
	overflow: hidden;
	border-radius: 0.6rem;
	justify-content: center;
	align-items: center;
}
.card-body {
	padding: 1rem;
	overflow-wrap: break-word;
	border-radius: 0.6rem;
}
.card-desc {
	font-size: 0.8rem;
	line-height: 1.5;
	text-align: left;
	overflow-wrap: break-word;
}
.card-link {
	margin-top: 1rem;
	width: 2rem;
	justify-content: center;
	display: flex;
	padding: 5px;
	border-radius: 5px;
	cursor: pointer;
	background-color: #eee !important;
}
