* {
	background-color: inherit;
	color: inherit;
}
.welcome {
	display: flex;
    flex-direction: column;
    width: -moz-fit-content;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    justify-items: center;
}
.info {
	padding: 100px 0 0 0;
	display: flex;
	flex-direction: row;
	flex: 1 1;
	gap: 5rem;
	padding: 7rem;
}
.name {
	font-size: 2rem;
}
.welcome-image {
	/* display: flex;
	flex: 1 2; */
}
.profile-picture {
	border-radius: 50%;
	object-fit: cover;
}
.welcome-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	line-height: 1.4rem;
}
.welcome-text {
	max-width: 45rem;
}
@media only screen and (max-width: 820px) {
	.welcome {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		padding: 0;
	}

	.info {
		display: flex;
		flex-direction: column;
		flex: 1 1;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		padding: 0;
		padding-top: 100px;
		width: 100%;
	}
	.welcome-content {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		line-height: 1rem;
		width: 100% !important;
		word-wrap: break-word;
		font-size: 1rem;
	}
	.welcome-content p {
		padding: 0rem 2rem 0px 2rem;
	}
}

@media only screen and (min-width: 1100px) { 
	.info {
		padding: 7rem 0 0 10rem;
	 }
}