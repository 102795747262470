.work-section {
	align-items: center;
	display: flex;
	justify-content: center;
}
.work-container {
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40rem;
}
.work-head {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-evenly;
    align-items: inherit;
    justify-content: space-between;
    column-gap: 15rem;
}
.work-heading {
    font-size: x-large;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.experience-list {
	align-items: flex-start;
	display: flex;
	/* flex: 2 1; */
	flex-direction: column;
	justify-content: flex-start;
    padding: 3rem;
}

.experience-item {
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 1rem;

    background: #F5F5F5;
    border: 1px solid rgb(9 9 9 / 19%);
    
    border-radius: 18px;
    padding: 15px;
}
.dark-bg {
    background: #333333 ;
    border: 1px solid orange;
}
.experience-period {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex: 1 1;
}
.experience-company{
    font-weight: bold;
    font-size: large;
}
.company-image {
    height: 8rem;
    position: relative;
    width: 8rem;
    border: 1px solid;
    border-radius: 8px;
    margin-top: 1rem;
}
.experience-details {
	flex: 1 1;
}
.experience-title {
	align-items: flex-start;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
    padding: 0;
    margin: 0;
}
.experience-desc {
	align-items: flex-start;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 1rem;
    font-size: 12px;
    opacity: 1;
    font-weight: 300;
}
.experience-desc p {
    font-size: small;
}

@media only screen and (max-width: 820px) { 
    .work-section {
        width: 100%;
    }
    .work-container {
        width: 100%;
    }
    .work-head {
        column-gap: 1rem;
    }
    .work-heading {
        font-size: large;
    }
    .company-image {
        height: 6rem;
        width: 6rem;
    }
}