.projects-section {
	align-items: center;
	display: flex;
	justify-content: center;
}
.projects-container {
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60rem;
}
.project-head {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-evenly;
    align-items: inherit;
    justify-content: space-between;
    column-gap: 15rem;
}
.project-heading {
    font-size: x-large;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.projects-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.show-more {
    background-color: azure;
    color: black;
    display: flex;
    height: 3rem;
    position: relative;
    margin-top: 10rem;
    padding: 0.1rem;
    border-radius: 1rem;
}
.show-more-btn {
    border-radius: 1rem;
}
@media only screen and (max-width: 820px) {  
    .project-head{
        column-gap: 1rem;
    }
    .project-heading {
        font-size: large;
    }
    .show-more { 

    }
}