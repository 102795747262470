.skill-filter-container {
	display: flex;
	align-items: center;
	justify-items: center;
}
.skill-filter-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
    gap:1rem;
    justify-content: center;
}
.skill-filter-item {
	display: flex;
	background-color: #eee;
	border-radius: 0.5rem;
    color: #000;
    cursor: pointer;
    font-weight: 800;
    /* margin: 0.5rem; */
    padding: 0.5rem 1rem;
    transition: all .3s ease;
}
.skill-filter-item:hover  {
    background-color: rgb(124, 209, 247);
}
.item-active {
    background-color: rgb(124, 209, 247);
}
