.social-icons {
	display: flex;
	gap: 1rem;
	transition: all 0.2s ease-in-out 0s;
	width: 30rem;
	justify-content: center;
	border-radius: 0.5rem;
}

.slideIn {
	box-shadow: -3px 3px 3px 3px rgb(220, 81, 81);
}

@media only screen and (max-width: 820px) {
	.social-icons {
		width: inherit;
	}
}
@media only screen and (max-width: 1100px) {
	.social-icons {
		padding: 0rem 0 0 20%;
	}
}
