* :after, :before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.dark {
	background-color: #282c34;
	color: white;
	height: 100vh;
}
.light {
	background-color: #f5f7f8;
	color: black;
}
.header {
	background: inherit;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
    position: sticky!important;
    position: -webkit-sticky!important;
	/* position: fixed; */
}
::-webkit-scrollbar {
	width: 0.7rem;
}

::-webkit-scrollbar-thumb {
	background-color: #818181;
	border-radius: 1rem;
}
@media screen and (max-width: 450px) {
	.header {
		background: inherit;
		justify-content: space-between;
		position: fixed;
		width: 100%;
		z-index: 2;
		padding-top: 0.5rem;
	}
}
